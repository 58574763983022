import * as React from "react";
import Header from "../components/header";
import About from "../components/about";
import Experience from "../components/experience";
import Title from "../components/title";
import Footer from "../components/footer";
import smoothscroll from 'smoothscroll-polyfill';
import "../global.css";

const pageStyles = {
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  background: "linear-gradient(180deg, #1B1B35 84%, #422157 100%)",
  overflowX: "hidden",
  minHeight: "100vh",
  minWidth: "80vw",
  padding: "0px",
  display: "flex",
  flexDirection: "column",
}

const column = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}

const IndexPage = () => {
  React.useEffect(() => {
    smoothscroll.polyfill();
    window.__forceSmoothScrollPolyfill__ = true;
  });

  return (
    <main style={pageStyles}>
      <div style={column} className="mobileWidth">
        <Header/>
        <Title/>
        <About/>
        <Experience/>
        <Footer/>
      </div>
    </main>
  )
}

export default IndexPage;
