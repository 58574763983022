import * as React from "react";
import me from "../images/me.png";
import resume from "../static/Martin-Capparelli-01-22.pdf";

const headerStyles = {
  width: "90%",
  height: "69px",
  zIndex: "100"
}

const headerMovileStyles = {
  ...headerStyles,
  width: "100%",
}

const headerContent = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  paddingTop: "15px",
  paddingBottom: "15px",
  alignItems: "center",
}

const imageStyle = {
  maxWidth: "45px",
  maxHeight: "45px",
  marginLeft: "45px",
  padding: "0px",
  margin: "0px"
}

const headerElementsStyle = {
  display: "flex",
  flexDirection: "row",
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: "200",
  fontSize: "18px",
  left: "15px"
}

const navigationButtons = {
  marginRight: "45px",
  textDecoration: "none",
  padding: "0px",
  color: "white",
  alignSelf: "center",
}

const Header = () => {
  const headerRef = React.createRef();
  const HEADER_HEIGHT = 250;
  const MAX_OPACITY = 0.8;

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  const handleScroll = () => {
    const scrollPercentaje = Math.min(HEADER_HEIGHT, window.scrollY);
    const opacity = (scrollPercentaje/HEADER_HEIGHT) * MAX_OPACITY;

    if(headerRef.current) {
      if (window.pageYOffset > 0) {
        headerRef.current.style.background = `rgba(0,0,0,${opacity})`;
      } else {
        headerRef.current.style.background = "";
      }
    }
  }

  const handleImageClick = () => {
    window.scrollTo(0, 0);
  }
  
  return (
    <>
      <div style={headerStyles} className="hideAll" ref={headerRef}>
        <div style={headerContent}>
          <img src={me} alt="profile" style={imageStyle} onClick={handleImageClick} className="clickable"/>
          
          <div style={headerElementsStyle}>
            <a href="#about" style={navigationButtons}>About</a>
            <a href="#experience" style={navigationButtons} >Experience</a>
            <a href="https://medium.com/@martincapparelli" target="_blank" rel="noreferrer" style={navigationButtons}>Blog</a>
            <a href={resume} target="_blank" rel="noreferrer" style={navigationButtons}>Resume</a>
          </div>
        </div>
      </div>

      <div className="showAll" style={headerMovileStyles} ref={headerRef}>
        <div style={{...headerContent, width: "100%", padding: "20px"}}>
          <img src={me} alt="profile" style={imageStyle} onClick={handleImageClick} className="clickable"/>
          
          <div style={headerElementsStyle}>
            <a href={resume} target="_blank" rel="noreferrer" style={navigationButtons} style={{...navigationButtons, marginRight: "0px"}}>Resume</a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header;