import * as React from "react";
import MainCard from "./cards/mainCard";
import PreviewCard from "./cards/previewCard";
import { enano, customily, naturalTech, itba } from "../data/data";

const mainStyle = {
  width: "70%",
  paddingTop: "50px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center"
}

const experienceStyles = {
  display: "flex",
  flexDirection: "row",
  width: "100%",
}

const textStyle = {
  color: "white",
  fontFamily: "Manrope",
}

const titleText = {
  ...textStyle,
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "30px",
  marginBottom: "15px",
  width: "100%",
}

const column = {
  display: "flex",
  flexDirection: "column",
  overflowY: "scroll",
}

const mainCard = {
  width: "57%",
  paddingRight: "10px"
}

const columnCard = {
  width: "43%",
  paddingLeft: "10px",
  height: "530px",
  overflowY: "auto"
}

const columnMobileCard = {
  width: "100%",
}

const Experience = () => {
  const [mainCardHeight, setMainCardHeight] = React.useState(530);
  const [colStyle, setColStyle] = React.useState(column);
  
  // Customily as default for being on top.
  const [selectedCard, setSelectedCard] = React.useState("customily");
  const [currentData, setCurrentData] = React.useState(customily);

  React.useEffect(() => {
    const newColumnStyle = {
      ...column,
      height: mainCardHeight
    }

    setColStyle(newColumnStyle);
  }, [mainCardHeight]);

  return (
    <div id="experience" style={mainStyle}> 
      <div className="hideAll" style={titleText}>Job Experience</div>
      <div className="showAll" style={{...titleText, marginTop: "50px"}}>Job Experience</div>

      <div className="hideAll" style={experienceStyles}>
        <div style={mainCard}>
          <MainCard data={currentData} setHeight={setMainCardHeight}/>
        </div>
        
        <div style={columnCard}>
          <div style={colStyle}>
            <PreviewCard data={customily} selected={selectedCard} setSelected={setSelectedCard} cardId="customily" 
              setData={() => setCurrentData(customily)}/>
            <PreviewCard data={enano} selected={selectedCard} setSelected={setSelectedCard} cardId="enano" 
              setData={() => setCurrentData(enano)} />
            <PreviewCard data={naturalTech} selected={selectedCard} setSelected={setSelectedCard} cardId="ntech" 
              setData={() => setCurrentData(naturalTech)} />
            <PreviewCard data={itba} selected={selectedCard} setSelected={setSelectedCard} cardId="itba" 
              setData={() => setCurrentData(itba)} />
          </div>
        </div>
      </div>

      <div className="showAll" style={experienceStyles}>
        <div style={columnMobileCard}>
          <div>
            <PreviewCard data={customily} selected={selectedCard} setSelected={setSelectedCard} isStatic={true} cardId="customily" />
            <PreviewCard data={enano} selected={selectedCard} setSelected={setSelectedCard} isStatic={true} cardId="enano" />
            <PreviewCard data={naturalTech} selected={selectedCard} setSelected={setSelectedCard} isStatic={true} cardId="ntech" />
            <PreviewCard data={naturalTech} selected={selectedCard} setSelected={setSelectedCard} isStatic={true} cardId="itba" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Experience;