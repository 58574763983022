import React from "../images/logos/react.svg";
import Typescript from "../images/logos/ts.svg";
import Vue from "../images/logos/vue.svg";
import Docker from "../images/logos/docker.svg";
import Express from "../images/logos/ex.svg";
import Net from "../images/logos/net.svg";
import Java from "../images/logos/java.svg";
import PSQL from "../images/logos/psql.svg";
import Coffe from "../images/work/coffe.svg";
import House from "../images/work/house.svg";

export const aboutMe = {
    paragraph1: "Graduated from ITBA, I’m a software engineer from Buenos Aires. I consider myself a creative and committed person with vast experience not only in both, front and backend development, but also developing scalable architectures.",
    paragraph2: "Currently self-learning Web3 and decetralized technologies to dig deeper into this new world. Apart from that, I’m also passionate about cooking and making music. I like to flirt with Java and .Net, sometimes with Angular and Vue, but my true loves are Typescript and React."
} 

export const customily = {
    company: "Customily | E-Commerce",
    type: "E-Commerce",
    dates: "Apr 2021 - Present",
    role: "Sr. Software Engineer",
    description:'Some the most outstanding tasks that I had been in charge of were: ',
    description2:'Building test suites for multiple Node JS services in Jest. Developing a complete CI pipeline for microservices in Github Actions. Integrating third party applications for image processing. Splitting an old monolith APP into microservices. Developing an async and distributed pipeline, based on queues, for automatic updating components based on user’s actions. Developing features and Issue fixing.',
    country: "Fully Remote | St. Louis - USA",
    jobSite: House,
    technologies: [Typescript, Net, Vue, Docker, PSQL], //jest
}

export const enano = {
    company: "Enano | Robotics",
    type: "Robotics",
    dates: "Apr 2020 - Apr 2021",
    role: "Technical Lead Engineer",
    description: "As a Teachnical Lead Engineer I had been involved not only in developing tasks but also in team building, code review, issue tracking, tasks planification, and working side by side with the Product Owner supporting him from the technical and side looking for possible business needs too. Also, I was in charge of maintenance of the whole developing and production infrastructure.",
    description2: "The most important goal was to build a real time application to control robots over the internet. The idea was to control it with a digital joystick and a real time video feed. The main challenge was to deal with performance and stability issues that could compromise the real sense of control.",
    country: "Fully Remote | London - UK",
    jobSite: House,
    technologies: [Typescript, React, Docker, Express, PSQL]
}

export const naturalTech = {
    company: "Natural Tech | NLP",
    type: "NLP",
    dates: "Apr 2019 - Apr 2020",
    role: "Full Stack Engineer",
    description: "As part of the team I have been in charge of two main projects on the whole IT department, in which I managed Design of User Interface/Experience for real-time Dynamic Reports. Redesign of multiple satellital applications (Back Office and Demos mainly) and the new website. Design and Deployment of the whole cloud architecture.",
    description2: "Also I had developed in pairs, with a computational linguist, many functionalities for the NLP module: RDF Triplets modeling to adapt multiple languages: Migration from Freelings to StanfordNLP. Adaptation of sub-verbal trees from Spanish to English.",
    country: "Fully Remote | Buenos Aires - ARG",
    jobSite: House,
    technologies: [Typescript, React, Docker, Express, PSQL]
}

export const itba = {
    company: "ITBA | ERP",
    type: "ERP",
    dates: "Jan 2018 - Apr 2019",
    role: "Full Stack Developer",
    description: "Development of an integral management system for pharmaceutical groups providing them with several administrative tools to help them safely and efficiently with their informatic needs.",
    description2: "As part of the developer team I had been involved mainly in multiples tasks including developing new features such as: Medicines Booking System for clients. Communication Protocol Implementation for Epson TM-T900FA fiscal printer. ACL system that includes a notification stack for monitoring. REST API design to communicate the main APP and multiple Zebra HandHelds.",
    country: "In Office | Buenos Aires - ARG",
    jobSite: Coffe,
    technologies: [Java, PSQL, Docker]
}