import * as React from "react";
import ImageContainer from "../imageContainer/imageContainer";

const card = {
  background: "#474462",
  borderRadius: "10px",
  minHeight: "240px",
  marginBottom: "20px",
}

const content = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "25px",
}

const textStyle = {
  color: "white",
  fontFamily: "Manrope",
}

const technologies = {
  display: "flex",
  marginTop: "5px"
}

const companyAndTime = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}

const subtitles = {
  ...textStyle,
  fontWeight: "700",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%"
}

const text = {
  ...textStyle,
  fontWeight: "200"
}

const country = {
  ...text,
  display: "flex",
  alignItem: "end"
}

const role = {
  ...textStyle,
  fontSize: "28px",
  display: "flex",
}

const PreviewCard = (props) => {
  const [opacity, setOpacity] = React.useState(0.2);
  const [height, setHeight] = React.useState(240);

  const cardRef = React.useRef(null);

  React.useEffect(() => {
    if(props.selected !== props.cardId && !props.isStatic) {
      setOpacity(0.6);
    } else {
      setOpacity(1);
    }
  }, [props.selected]);

  React.useEffect(() => {
    if(cardRef.current.offsetHeight) {
      setHeight(cardRef.current.offsetHeight);
    }
  }, [props]);

  const handleClick = () => {
    if(props.setSelected && props.setData) {
      props.setSelected(props.cardId);
      props.setData();
    }
  }

  return (
    <div style={{...card, opacity: opacity}} ref={cardRef} className="clickable borderOnHover" onClick={handleClick}>
      <div style={{...content, height: height - 45}}>
        <div style={companyAndTime}>
          <div style={subtitles}>
            <div>{props.data.company}</div>
            <div style={text} className="hideSmallDevice">{props.data.dates}</div>
          </div>
        </div>

        <div style={role}>{props.data.role}</div>
        
        <div>
          <div style={text}>Technologies</div>
          <div style={technologies}>
            {
              props.data.technologies.slice(0, 3).map((each) => {
                return <ImageContainer small={true} src={each}/>
              })
            }
          </div>
        </div>

        <div style={country} className="hideSmallDevice">
          <div style={{marginRight: "5px"}}>
            <ImageContainer mini={true} src={props.data.jobSite} />
          </div>
          <div style={{alignSelf: "center"}}>{props.data.country}</div>
        </div>
      </div>
    </div>
  );
}

export default PreviewCard;