import * as React from "react";
import { toast } from 'wc-toast';
import me from "../images/me.png";
import github from "../images/footer/ghub.svg";
import telegram from "../images/footer/telegram.svg";
import gmail from "../images/footer/gmail.svg";
import linkedIn from "../images/footer/lin.svg";
import medium from "../images/footer/medium.svg";

const aboutStyles = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  height: "calc(20vh + 69px)",
  width: "90%",
}

const aboutMobileStyles = {
  ...aboutStyles,
  flexDirection: "column",
  justifyContent: "center",
  marginBottom: "50px",
  height: "auto"
}

const textStyle = {
  color: "white",
  fontFamily: "Manrope",
}

const nameContainer = {
  display: "flex",
  width: "100%",
  alignItems: "center",
}

const name = {
  ...textStyle,
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "20px",
  marginLeft: "15px"
}

const imageStyle = {
  maxWidth: "40px",
  maxHeight: "40px",
  padding: "0px",
  margin: "0px"
}

const logoStyle = {
  maxWidth: "35px",
  maxHeight: "35px",
  padding: "0px",
  marginRight: "0px"
}

const logoContainer = {
  display: "flex",
  width: "30%",
  alignItems: "center",
  justifyContent: "space-between"
}

const logoMobileContainer = {
  ...logoContainer,
  width: "100%",
  justifyContent: "space-evenly",
  marginTop: "30px"
}

const Footer = () => {
  
  const handleMailClick = () => {
    navigator.clipboard.writeText("martinfedericocapparelli@gmail.com");
    toast('Email copied to clipboard', { 
      icon: { type: 'custom', content: '📬' },
      theme: {
        type: 'custom',
        style: { background: 'white', color: '#422157' },
      },
    });
  };

  return (
    <>
      <wc-toast></wc-toast>
      <div style={aboutStyles} className="hideAll" id="footer">  
        <div style={nameContainer}>
          <img src={me} alt="profile" style={imageStyle} />
          <div style={name}>Martín F. Capparelli</div>
        </div>

        <div style={logoContainer}>
          <a target="_blank" rel="noreferrer" href="https://github.com/mcapparelli"><img src={github} alt="github" style={logoStyle} /></a>
          <a target="_blank" rel="noreferrer"href="https://www.linkedin.com/in/martin-federico-capparelli-49b38797/"><img src={linkedIn} alt="linkedIn" style={logoStyle} /></a>
          <img src={gmail} alt="gmail" className="clickable" style={logoStyle} onClick={handleMailClick}/>
          <a target="_blank" rel="noreferrer" href="https://medium.com/@martincapparelli"><img src={medium} alt="medium" style={logoStyle} /></a>
        </div>
      </div>

      <div style={aboutMobileStyles} className="showAll" id="footer">  
        <div style={logoMobileContainer}>
          <a target="_blank" rel="noreferrer" href="https://github.com/mcapparelli"><img src={github} alt="github" style={logoStyle} /></a>
          <a target="_blank" rel="noreferrer"href="https://www.linkedin.com/in/martin-federico-capparelli-49b38797/"><img src={linkedIn} alt="linkedIn" style={logoStyle} /></a>
          <img src={gmail} alt="gmail" className="clickable" style={logoStyle} onClick={handleMailClick}/>
          <a target="_blank" rel="noreferrer" href="https://medium.com/@martincapparelli"><img src={medium} alt="medium" style={logoStyle} /></a>
        </div>
      </div>
    </>
  )
}

export default Footer;
