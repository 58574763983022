import * as React from "react";
import ImageContainer from "../imageContainer/imageContainer";

const card = {
  background: "#474462",
  borderRadius: "10px",
  height: "530px",
  overflowY: "auto",
}

const content = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "32px",
}

const textStyle = {
  color: "white",
  fontFamily: "Manrope",
}

const technologies = {
  display: "flex",
  marginTop: "5px",
  marginBottom: "15px"
}

const companyAndTime = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}

const subtitles = {
  ...textStyle,
  fontWeight: "700",
  marginBottom: "15px"
}

const text = {
  ...textStyle,
  fontWeight: "200"
}

const mainText = {
  ...text,
  fontSize: "17px"
}

const role = {
  ...textStyle,
  fontSize: "32px",
  display: "flex",
  marginBottom: "15px"
}

const country = {
  ...text,
  display: "flex",
  alignItem: "end"
}

const MainCard = (props) => {
  return (
    <div style={card}>
      <div style={content}>
        <div>
          <div style={companyAndTime}>
            <div style={subtitles}>{props.data.company}</div>
            <div style={text}>{props.data.dates}</div>
          </div>

          <div style={role}>{props.data.role}</div>
          
          <div style={mainText}>{props.data.description}</div>

          <br/>

          <div style={{...mainText, marginBottom: "20px"}}>{props.data.description2}</div>
        </div>
        
        <div>
            <div style={subtitles}>Technologies</div>

            <div style={technologies}>
            {
              props.data.technologies.map((each) => {
                return <ImageContainer src={each}/>
              })
            }
            </div>

            <div style={country}>
              <div style={{marginRight: "5px"}}>
                <ImageContainer mini={true} src={props.data.jobSite} />
              </div>
              <div style={{alignSelf: "center"}}>{props.data.country}</div>
            </div>
          </div>
      </div>
    </div>
  )
}

export default MainCard;