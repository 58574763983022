import * as React from "react";
import HiImage from "../images/memoji/hi.svg";
import Typewriter from "typewriter-effect/dist/core";

const titleStyles = {
  display: "flex",
  flexDirection: "row",
  marginTop: "10vh",
  marginBottom: "17vh",
  maxWidth: "70vw"
}

const titleMobileStlye = {
  width: "100%",
  flexDirection: "column",
}

const textStyle = {
  color: "white",
  fontFamily: "Manrope",
}

const bigText = {
  ...textStyle,
  fontSize: "75px",
  fontStyle: "normal",
  fontWeight: "800",
  lineHeight: "100.68%"
}

const smallText = {
  ...textStyle,
  fontStyle: "normal",
  fontWeight: "200",
  fontSize: "22px",
  lineHeight: "25px",
  paddingBottom: "20px"
}

const textAlign = {
  WebkitAlignSelf: "flex-end",
  marginLeft: "55px",
  zIndex: "10"
}

const imageStyle = {
  marginRight: "55px",
  zIndex: "10"
}

const shadow = {
  width: "360px",
  height: "360px",
  background: "#803491",
  filter: "blur(150px)",
  position: "absolute",
  marginLeft: "-45px",
  marginTop: "60px",
  opacity: "0.9"
}

const mobileShadow = {
  ...shadow,
  width: "70%",
  height: "70%",
}

const keywordStyle = {
  color: "#9FF5BB",
  margin: "0px",
  marginLeft: "8px",
}

const Title = () => {

  React.useEffect(() => {
    new Typewriter('#typewriter', {
      strings: ['create', 'build', 'plan'],
      autoStart: true,
      loop: true
    });
  });

  return (
    <>
      <div style={titleStyles} id="title" className="hideAll">
        <img src={HiImage} className="mediumImage hideAll" alt="Hi!" style={imageStyle}/>      
        <div style={shadow}/>
        
        <div style={textAlign}>
          <div style={smallText}>Software Engineer</div>
            <div className="mediumTexts" style={bigText}>I am Martín,</div>
            <div className="mediumTexts" style={{...bigText, display: "flex"}}>
              let's
              <div id="typewriter" style={keywordStyle} />
            </div>
            <div className="mediumTexts" style={bigText}>together!</div>
        </div>
      </div>

      <div style={titleMobileStlye} id="title" className="showAll mobileMargins">
        <img src={HiImage} className="mediumImage showAll" alt="Hi!" style={{marginTop: "20px", maxHeight: "200px", zIndex: "100"}}/>      
        <div style={mobileShadow}/>
        
        <div style={{zIndex: "10", textAlign: "center", marginBottom: "100px", marginTop: "20px"}}>
          <div style={{...smallText, textAlign: "center", marginTop: "30px", width: "100%"}}>Software Engineer</div>
            <div className="mediumTexts" style={bigText}>
              I am Martín, let's <span style={keywordStyle}>build </span>
              together!
              </div>
        </div>
      </div>

    </>
  )
}

export default Title;