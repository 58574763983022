import * as React from "react";

const image = {
  objectFit: "contain",
  marginRight: "10px",
}

const small = {
  height: "30px",
  width: "30px",
}

const mini = {
  height: "25px",
  width: "25px",
}

const big = {
  height: "40px",
  width: "40px",
}

const ImageContainer = (props) => {
  const style = props.small ? {...image, ...small} : props.mini ? {objectFit: "contain", ...mini} : {...image, ...big}; 

  return (
   <img src={props.src} style={style} alt="logo circle"/>
  );
}

export default ImageContainer;