import * as React from "react";
import OkImage from "../images/memoji/ok.svg";
import ReactLogo from "../images/technologies/react.svg";
import TypescriptLogo from "../images/technologies/typescript.svg";
import PsqlLogo from "../images/technologies/psql.svg";
import VueLogo from "../images/technologies/vue.svg";
import NetLogo from "../images/technologies/net.svg";
import JavaLogo from "../images/technologies/java.svg";
import ImageContainer from "./imageContainer/imageContainer";
import { aboutMe } from "../data/data";

const aboutStyles = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  height: "50vh",
  width: "70%",
  zIndex: "100",
  marginTop: "70px",
  marginBottom: "70px"
}

const imageStyle = {
  maxWidth: "20%",
  maxHeight: "220px",
  marginLeft: "40px"
}

const textContainer = {
  maxWidth: "80%"
}

const textStyle = {
  color: "white",
  fontFamily: "Manrope",
}

const titleText = {
  ...textStyle,
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "30px",
}

const contentText = {
  ...textStyle,
  fontStyle: "normal",
  fontWeight: "200",
  fontSize: "18px",
  maxWidth: "640px"
}

const greenContentText = {
  ...contentText,
  color: "#9FF5BB",
}

const technologies = {
  display: "flex",
}

const About = () => {
  return (
    <div style={aboutStyles} id="about"> 
      {/* Text section */}     
      <div style={textContainer} className="expand mobileMargins">
        <div style={titleText}>About Me</div>
        <div style={greenContentText}>Software Engineer | Buenos Aires, Argentina 🇦🇷</div>
        <p style={contentText}>
          {aboutMe.paragraph1} 
          <br/><br/>
          {aboutMe.paragraph2} 
          <br/><br/>
          Here are a few technologies I’ve been working recently with:
        </p>

        <div style={technologies}>
          <ImageContainer src={TypescriptLogo}/>
          <ImageContainer src={JavaLogo}/>
          <ImageContainer src={NetLogo}/>
          <ImageContainer src={ReactLogo}/>
          <ImageContainer src={VueLogo}/>
          <div className="hideAll"><ImageContainer src={PsqlLogo}/></div>
        </div>
      </div>

      {/* Memoji section */}     
      <img src={OkImage} className="hideAll" alt="Ok!" style={imageStyle}/>
    </div>
  )
}

export default About;